import './App.css';
import {Outlet} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import {Container} from "react-bootstrap";
import React from "react";

function App() {
  return (
    <>
      <Container className="app-container">
        <Outlet />
      </Container>
    </>
  );
}

export default App;
