import { getDatabase, ref, set, update, get, serverTimestamp, query, orderByChild } from 'firebase/database';
import { app } from '../firebaseConfig';

const database = getDatabase(app);

function getIdeaRef(code) {
  return ref(database,`ideas/${code}`);
}

export function addIdea(code, data) {
  return new Promise((resolve, reject) => {
    set(getIdeaRef(code), {
      ...data,
      createdAt: serverTimestamp()
    })
      .then(() => {
        resolve();
      })
      .catch(e => {
        reject(e);
      });
  });
}

export function updateIdea(code, data) {
  return new Promise((resolve, reject) => {
    update(getIdeaRef(code), {
      ...data,
      updatedAt: serverTimestamp()
    })
      .then(() => {
        resolve();
      })
      .catch(e => {
        reject(e);
      });
  });
}

export function getIdea(code) {
  return new Promise((resolve, reject) => {
    get(getIdeaRef(code))
      .then(snapshot => {
        resolve(snapshot.exists() ? snapshot.val() : null);
      })
      .catch(e => {
        reject(e);
      });
  });
}

export function getIdeas() {
  return new Promise((resolve, reject) => {
    get(query(ref(database, 'ideas'), orderByChild('createdAt')))
      .then(snapshot => {
        const processedData = [];

        snapshot.forEach((child) => {
          const idea = child.val();

          for (const timestampKey of ['createdAt', 'updatedAt']) {
            if (idea.hasOwnProperty(timestampKey)) {
              idea[timestampKey] = new Date(idea[timestampKey]).toLocaleString('et-EE', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric'
              });
            }
          }

          processedData.push({
            ...idea,
            id: child.key
          });
        });

        resolve(processedData.reverse());
      })
      .catch(e => {
        reject(e);
      });
  });
}

export const ideaInitialState = {
  email: '',
  product: '',
  description: '',
  purpose: '',
  researchQuestion: '',
  researchMethod: '',
  input: '',
  actions: '',
  outputs: '',
  impact: ''
};