import React, { useState, useEffect } from "react";
import { getIdeas } from "../database";
import Table from 'react-bootstrap/Table';
import { Container, OverlayTrigger, Tooltip } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from "react-bootstrap/Button";
import { usePapaParse } from 'react-papaparse';
import fileDownload from 'js-file-download'
import InputGroup from "react-bootstrap/InputGroup";
import { useLoaderData } from "react-router-dom";

export async function loader(){
    return await getIdeas();
}

function Results() {
    const data = useLoaderData();
    const [filteredData, setFilteredData] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const { jsonToCSV } = usePapaParse();

    useEffect(() => {
        setFilteredData(
            data.filter((item) => {
                return item.id.toLowerCase().includes(searchTerm.toLowerCase());
            })
        );
    }, [data, searchTerm]);

    const columns = [
        {
            dataField: "id",
            text: "ID",
            filter: {
                onFilter: (filterValue, row) => {
                    return row.id.includes(filterValue);
                },
            },
        },
        { dataField: "email", text: "E-posti aadress" },
        { dataField: "product", text: "Toode" },
        { dataField: "description", text: "Lühikirjeldus" },
        { dataField: "purpose", text: "Eesmärk" },
        { dataField: "researchQuestion", text: "Uurimisküsimus" },
        { dataField: "researchMethod", text: "Uurimismeetod" },
        { dataField: "input", text: "Sisend" },
        { dataField: "actions", text: "Tegevused" },
        { dataField: "outputs", text: "Väljundid" },
        { dataField: "impact", text: "Mõju" },
        { dataField: "createdAt", text: "Lisatud" },
        { dataField: "updatedAt", text: "Uuendatud" }
    ];

    function downloadResults() {
        const dataKeys = columns.map(single => single.dataField);
        const results = jsonToCSV({
            fields: columns.map(single => single.text),
            data: filteredData.map(single => dataKeys.map(key => single.hasOwnProperty(key) ? single[key] : ''))
        });

        fileDownload(results, 'results.csv', 'text/csv')
    }

    return(
        <Container>
            <h1 className="text-center mt-5">Vastuste tabel</h1>
            <br/>
            <br/>
            <Row>
                <Col xs md="4">
                    <Form.Group className="mb-3">
                        <Form.Label>
                            <strong>Filtreeri ID kaupa</strong>
                        </Form.Label>
                        <InputGroup>
                            <Form.Control type="text" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                            <OverlayTrigger overlay={<Tooltip id="tooltip-download">Lae alla</Tooltip>}>
                                <Button variant="outline-success" onClick={downloadResults}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                         className="bi bi-download" viewBox="0 0 16 16">
                                        <path
                                          d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                                        <path
                                          d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
                                    </svg>
                                </Button>
                            </OverlayTrigger>
                        </InputGroup>
                    </Form.Group>
                </Col>
            </Row>
            <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        {columns.map((column) => (
                            <th key={column.dataField}>{column.text}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {filteredData.map((row) => (
                        <tr key={row.id}>
                            {columns.map((column) => (
                                <td key={column.dataField}>{row[column.dataField]}</td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Container>
    );
}

export default Results;