import { initializeApp } from 'firebase/app';
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCz7VCaMUjMjoNxAefE_drb4xcHqK8xgYs",
    authDomain: "educate-75064.firebaseapp.com",
    databaseURL: "https://educate-75064-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "educate-75064",
    storageBucket: "educate-75064.appspot.com",
    messagingSenderId: "939187600957",
    appId: "1:939187600957:web:42d6b173774d66b7e17454"
};
// reCAPTCHA site key (public)
const recaptchaSiteKey = '6LdBNnUmAAAAAH-0YP4Pxj-maid0YKWBgw3b4tmS';

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider(recaptchaSiteKey),
    isTokenAutoRefreshEnabled: true
});