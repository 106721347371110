import React, {useEffect, useRef, useState} from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { ideaInitialState } from "../database";
import { Col, ButtonGroup, BreadcrumbItem, Breadcrumb, Tooltip, OverlayTrigger } from "react-bootstrap";
import { steps } from '../data';
import ProgressBar from "react-bootstrap/ProgressBar";
import InputGroup from "react-bootstrap/InputGroup";
import { Form as RouterForm } from "react-router-dom";


function IdeaForm({ code, data = null, successCallback }) {
  const mappedSteps = steps.map(step => step.id);
  const [step, setStep] = useState(steps[0]);
  const idea = data ? data : ideaInitialState;
  const [codeCopied, setCodeCopied] = useState(false);
  const inputRefs = useRef({});

  useEffect(() => {
    if (inputRefs.current[step.id]) {
      inputRefs.current[step.id].focus();
    }
  }, [step])

  function currentIndex() {
    return mappedSteps.indexOf(step.id);
  }

  function handleCopyCode() {
    navigator.clipboard.writeText(code)
      .then(() => {
        setCodeCopied(true);
      });
  }

  function handleBack(e) {
    e.preventDefault();
    const index = currentIndex();

    if (index !== 0) {
      setStep(steps[index - 1]);
    }
  }

  function handleForward(e) {
    e.preventDefault();
    const index = currentIndex();

    if (index < mappedSteps.length - 1) {
      setStep(steps[index + 1]);
    }
  }

  function breadcrumbText(id, text) {
    return step.id === id ? <strong>{ text }</strong> : text;
  }

  function getPercentage() {
    let now = 100 / mappedSteps.length * (mappedSteps.indexOf(step.id) + 1);

    if (now === 100) {
      now -= 0.1;
    }

    return now;
  }

  function formGroupClass(id) {
    return step.id !== id ? 'd-none' : '';
  }

  return(
    <RouterForm method="post" noValidate>
      <InputGroup className="mb-5">
        <InputGroup.Text id="session-code">
          <strong>Sessiooni kood</strong>
        </InputGroup.Text>
        <Form.Control
          aria-label="Sessiooni kood"
          aria-describedby="session-code"
          value={code}
          readOnly
        />
        <OverlayTrigger overlay={<Tooltip id="copy-to-clipboard">Kopeeri</Tooltip>}>
          <Button variant="outline-success" onClick={handleCopyCode}>
            { codeCopied ?
              <svg xmlns="http://www.w3.org/2000/svg" width="1.25em" height="1.25em" fill="currentColor"
                   className="bi bi-clipboard-check" viewBox="0 0 16 16">
                <path fillRule="evenodd"
                      d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
                <path
                  d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
                <path
                  d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>
              </svg>
              :
              <svg xmlns="http://www.w3.org/2000/svg" width="1.25em" height="1.25em" fill="currentColor"
                   className="bi bi-clipboard" viewBox="0 0 16 16">
                <path
                  d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
                <path
                  d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>
              </svg>
            }
          </Button>
        </OverlayTrigger>
      </InputGroup>
      <h1 className="text-center">{ step.text }</h1>
      <p className="text-center">{ step.description }</p>
      <br />

      <Breadcrumb>
        {
          steps.map(step =>
            <BreadcrumbItem key={step.id} href="#" active>{ breadcrumbText(step.id, step.text) }</BreadcrumbItem>
          )
        }
      </Breadcrumb>

      <Col className="text-center">
        <ProgressBar now={getPercentage()} />
        <p id="descriptionText">{getPercentage()}%</p>
      </Col>

      <div className="text-center mb-5">
        <ButtonGroup>
          <Button variant="outline-danger" onClick={handleBack} disabled={currentIndex() === 0}>← Tagasi</Button>
          {
            currentIndex() !== mappedSteps.length -1 ?
              <Button variant="outline-success" onClick={handleForward}>Edasi →</Button>
              :
              <Button type="submit" variant="outline-success">{data !== null ? 'Uuenda' : 'Lõpeta'}</Button>
          }
        </ButtonGroup>
      </div>

      <Form.Group className={formGroupClass('personal-data')}>
        <Form.Label>E-posti aadress</Form.Label>
        <Form.Control
          ref={(el) => (inputRefs.current['personal-data'] = el)}
          type="email"
          placeholder="Sisesta e-posti aadress..."
          defaultValue={idea.email}
          name="email" />
        <br />
        <Form.Label>Toote nimi</Form.Label>
        <Form.Control
          type="text"
          placeholder="Sisesta toote nimi..."
          defaultValue={idea.product}
          name="product" />
        <br />
        <Form.Label>Toote lühikirjeldus</Form.Label>
        <Form.Control
          as="textarea"
          rows={4}
          cols={20}
          placeholder="Sisesta toote lühikirjeldus..."
          defaultValue={idea.description}
          name="description" />
      </Form.Group>

      {
        steps.slice(1).map(step => {
          const inputName = step.id.replace(/-[a-zA-Z]/g, match => match.substring(1).toUpperCase());
          const helpBlockId = `${inputName}HelpBlock`;

          return (
            <Form.Group key={step.id} className={formGroupClass(step.id)}>
              <Form.Control
                ref={(el) => (inputRefs.current[step.id] = el)}
                as="textarea"
                rows={10}
                cols={70}
                defaultValue={idea[step.id]}
                name={inputName}
                aria-describedby={helpBlockId}/>
              <Form.Text as="div" id={helpBlockId} className="mt-4">
                {
                  step.help.map((text, index) =>
                    <p key={index}>{text}</p>
                  )
                }
              </Form.Text>
            </Form.Group>
          );
        })
      }
    </RouterForm>
  );
}

export default IdeaForm;