import { Link, useRouteError } from "react-router-dom";
import React from "react";
import {Container} from "react-bootstrap";

function ErrorBoundary() {
  const error = useRouteError();

  return(
    <Container className="error-container">
      <header className="text-center">
        <h1 className="mb-5">Viga!</h1>
      </header>
      <main className="text-center">
        <p className="text-danger fs-2">{error.status}</p>
        <p className="fw-bold">
          <i>{error.statusText || error.message}</i>
        </p>
        <Link to='/' className='btn btn-outline-danger mt-5 btn-lg'>Avalehele</Link>
      </main>
    </Container>
  );
}

export default  ErrorBoundary;