export const steps = [
  {
    id: 'personal-data',
    text: 'Personaalandmed',
    description: 'Teie personaalandmed & toote esmane info',
  },
  {
    id: 'purpose',
    text: 'Eesmärk',
    description: 'Sõnastatud koosloome eesmärk',
    help: [
      'Välja töötatud prototüüp, toode, mis…',
      'Uued praktikad, mis…',
      'Toote X uus funktsionaalsus, mis...'
    ]
  },
  {
    id: 'research-question',
    text: 'Uurimisküsimus',
    description: 'Sõnastatud koosloomega seotud uurimisküsimus(ed)',
    help: [
      'Mil määral paranevad/muutuvad/tõusevad...?',
      'Kuivõrd toetab toode...?',
      'Kuidas hindavad õppijad/õpilased...?'
    ]
  },
  {
    id: 'research-method',
    text: 'Uurimismeetod',
    description: 'Sõnastatud koosloomega seotud uurimismeetod',
    help: [
      'Välja töötatud prototüüp, toode, mis…',
      'Uued praktikad, mis…',
      'Toote X uus funktsionaalsus, mis...'
    ]
  },
  {
    id: 'input',
    text: 'Sisend',
    description: 'Mis on toote sisendid?',
    help: [
      'Lauaarvuti',
      'Interneti ühendus',
      'Valmis ja toimiv rakendus',
      'Õppijad - mis vanuserühmast ja kui palju?',
      'Õppijate eelnevad teadmised?',
      'Metoodiline/didaktiline/haridustehnoloogiline ekspertiis'
    ]
  },
  {
    id: 'actions',
    text: 'Tegevused',
    description: 'Mida toode teeb kasutajate jaoks? Mida kasutajad saavad tootega teha?',
    help: [
      'Kasutajalood:',
      'Õppija saab...',
      'Õppija kasutab seda funktsionaalsust...',
      'Õpetaja teeb...'
    ]
  },
  {
    id: 'outputs',
    text: 'Väljundid',
    description: 'Oodatav toode ja plaanitud väljundid, mida kasutajad kogevad?',
    help: [
      'Õppija on võimeline (leidma sobivaid strateegiaid, tegema otsuseid)...',
      'Õppija reflekteerib mõtestatult...',
      'Õppija kasutab ... funktsionaalsust, et...',
      'Õppija arendab...',
      'Õppija näitab paranenud...'
    ]
  },
  {
    id: 'impact',
    text: 'Mõju',
    description: 'Mis on toote oodatav mõju?',
    help: [
      'Omandatud oskused, strateegiad',
      'Testi tulemused',
      'Muutused uskumustes, mõtteviisis'
    ]
  }
];
