import React from "react";
import {Row, Col} from "react-bootstrap";
import IdeaForm from "../components/IdeaForm";
import {useParams, redirect} from "react-router-dom";
import {addIdea, getIdea} from "../database";

export async function loader({ params }) {
  const idea = await getIdea(params.code);

  if (idea) {
    throw new Response('', {
      status: 409,
      message: 'Võti on juba hõivatud!'
    });
  }

  return null;
}

export async function action({ params, request }) {
  const formData = await request.formData();
  await addIdea(params.code, Object.fromEntries(formData.entries()));

  return redirect('/?dialog=added');
}

function Add() {
  const { code } = useParams();

  return(
    <Row>
      <Col xs={12} lg={{ span: 8, offset: 2 }}>
        <IdeaForm code={code}></IdeaForm>
      </Col>
    </Row>
  );
}

export default Add;