import React from "react";
import {getIdea, updateIdea} from "../database";
import { Row, Col } from "react-bootstrap";
import IdeaForm from "../components/IdeaForm";
import {redirect, useLoaderData, useParams} from "react-router-dom";

export async function loader({ params }){
    const idea = await getIdea(params.code);

    if (!idea) {
        throw new Response('', {
            status: 404,
            statusText: 'Ideed pole leitud!'
        });
    }

    return idea;
}

export async function action({ params, request }) {
    const formData = await request.formData();
    await updateIdea(params.code, Object.fromEntries(formData.entries()));

    return redirect('/?dialog=updated');
}

function Edit() {
    const { code } = useParams();
    const data = useLoaderData();

    return(
      <Row>
          <Col xs={12} lg={{ span: 8, offset: 2 }}>
              <IdeaForm code={code} data={data}></IdeaForm>
          </Col>
      </Row>
    );
}

export default Edit;