import React, {useState} from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import InputGroup from 'react-bootstrap/InputGroup';
import {Row, Col, Alert} from "react-bootstrap";
import {useLoaderData, useNavigate} from "react-router-dom";
const randomize = require("randomatic");

export async function loader({ request }) {
    const url = new URL(request.url);
    const dialog = url.searchParams.get("dialog");

    if (dialog) {
        return {
            dialog: dialog
        };
    }

    return {};
}

function Front() {
    const [continueCode, setContinueCode] = useState("");
    const [validated, setValidated] = useState(false);
    const navigate = useNavigate();
    const {dialog} = useLoaderData();

    function handleCode(e) {
        e.preventDefault();

        const code = randomize("A0", 8);
        navigate(`add/${code}`);
    }

    function handleContinueCode(e) {
        const form = e.currentTarget;

        e.preventDefault();
        e.stopPropagation();

        if (form.checkValidity() === false) {
            setValidated(true);
        } else if (continueCode) {
            navigate(`edit/${continueCode}`);
        }
    }

    return(
      <Row>
          {dialog && dialog === 'added' && <Alert variant="success" dismissible><strong>Idee salvestatud!</strong> Teie andmed on salvestatud andmebaasi.</Alert>}
          {dialog && dialog === 'updated' && <Alert variant="success" dismissible><strong>Idee uuendatud!</strong> Teie andmed on salvestatud andmebaasi.</Alert>}

          <Col xs={12} lg={{ span: 8, offset: 2 }} className="text-center">
              <h1 className="mb-4">Avakuva</h1>
              <p>Ankeedi täitmiseks genereeri uus sessioonikood (<strong>NB! Koodi näete ankeedi vaates, salvestage see endale!</strong>).</p>
              <p className="mb-3">Kui on soov muuta/lisada vastuseid, kleebi sessioonikood jätkamislahtrisse.</p>

              <Form onSubmit={handleCode}>
                  <Button type="submit" variant="outline-success mb-5">Genereeri kood ja alusta →</Button>
              </Form>

              <Form noValidate validated={validated} onSubmit={handleContinueCode}>
                  <div className="fw-bold mb-3 mt-5">Muutke eksisteerivat ankeeti ↓</div>
                  <Row>
                      <Col xs md={{ span: 8, offset: 2 }}>
                          <InputGroup className="mb-3" hasValidation>
                              <Form.Control
                                bsPrefix="form-control text-center"
                                placeholder="Sisestage oma kood siia..."
                                aria-label="Koodi sisend"
                                value={continueCode}
                                onChange={(e) => setContinueCode(e.target.value.trim())}
                                required
                              />
                              <Button type="submit" variant="outline-success">Muuda vastuseid →</Button>
                              <Form.Control.Feedback type="invalid">
                                  Sessiooni kood puudub!
                              </Form.Control.Feedback>
                          </InputGroup>
                      </Col>
                  </Row>

              </Form>
          </Col>
      </Row>
    );
}

export default Front;