import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import Front, {loader as frontLoader} from "./pages/Front";
import Add, {
  loader as addLoader,
  action as addAction
} from "./pages/Add";
import Results, {loader as resultsLoader} from "./pages/Results";
import Edit, {
  loader as editLoader,
  action as editAction
} from "./pages/Edit";
import ErrorBoundary from "./pages/ErrorBoundary";
import App from "./App";

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        index: true,
        element: <Front />,
        loader: frontLoader
      },
      {
        path: 'add/:code',
        element: <Add />,
        loader: addLoader,
        action: addAction
      },
      {
        path: 'edit/:code',
        element: <Edit />,
        loader: editLoader,
        action: editAction
      },
      {
        path: '/results',
        element: <Results />,
        loader: resultsLoader
      }
    ]
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
